// src/App.js
import React, { useEffect, useState } from 'react';
import { Howl } from 'howler';
import './App.css';

const App = () => {
  const [keySequence, setKeySequence] = useState([]);
  const [showGitHubLink, setShowGitHubLink] = useState(false);
  const [powerOn, setPowerOn] = useState(false);
  const [flicker, setFlicker] = useState(false);
  const konamiCode = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];

  const [arrowVisible, setArrowVisible] = useState([false, false]); // Track which arrow is visible

  // Function to handle mouseover and mouseout for toggling arrows
  const handleMouseEnter = (index) => {
    setArrowVisible((prevState) => {
      const newState = [...prevState];
      newState[index] = true; // Show arrow on mouse enter
      return newState;
    });
  };

  const handleMouseLeave = (index) => {
    setArrowVisible((prevState) => {
      const newState = [...prevState];
      newState[index] = false; // Hide arrow on mouse leave
      return newState;
    });
  };


  useEffect(() => {
    const handleKeyDown = (event) => {
      setKeySequence((prevSequence) => [...prevSequence, event.key].slice(-10));
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [powerOn]);

  useEffect(() => {
    if (JSON.stringify(keySequence) === JSON.stringify(konamiCode)) {
      //alert('Konami Code Activated!');
      setShowGitHubLink(true);
    }
  }, [keySequence]);

  const handlePowerOn = () => {
    setPowerOn(true);
  }

  useEffect(() => {
    if(powerOn){
      setFlicker(true);
      setTimeout(() => {
        setFlicker(false);
        const sound = new Howl({
          src: ['./title_song.mp3'],
          autoplay: true,
          loop: false,
        });
      }, 300); // flicker duration
      setTimeout( () => {
        handleMouseEnter(0);
      }, 5000); // show arrow when loading finished
    }
  }, [powerOn]);
  
  return (
    <div className="App">
      {flicker && <div className="flicker"></div>}
      {!powerOn ? (
        <div className="power-on-button" onClick={handlePowerOn}>
          Power
        </div>
      ) : (
      <div className="contra-loading-screen animate">
          <div className="logo-container"><img src="/8bitlogo.jpg" style={{width: "80%"}} alt="Halvorsen Software Consulting"/></div>
          <div class="row">
            <div class="column">

            <div class="column-header">LINK SELECT</div>
            <div class="link-row">
              <span class="arrow-show" className={arrowVisible[0] ? 'arrow-show' : 'arrow-hide'}>→</span> <a href="/resume.html" onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={() => handleMouseLeave(0)}>RESUME</a>
              </div>
              <div class="link-row" style={{ 'margin-left' :'2em'}}>
              <span class="arrow-hide" className={arrowVisible[1] ? 'arrow-show' : 'arrow-hide'}>→</span> <a href="https://www.linkedin.com/in/eric-halvorsen/" onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={() => handleMouseLeave(1)}>LINKEDIN</a>
              </div>
            </div>
            

            <div class="column">
              <img src="headshotcolor.png" style={{width:"80%"}} alt="8-bit Portrait" />
            </div>
          </div>

          {showGitHubLink && (
            <div className="player-select-secret">
              <div className="player"><a href="http://github.com/eh7n">GITHUB (+30)</a></div>
            </div>
            )}
        </div>
      )}
    </div>
  );
};

export default App;